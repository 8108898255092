import { AxiosResponse } from 'axios';
import {ENTITY_CLIENT} from "@/api/misc/EntityClient";

/**
 * repository that deals with all the api requests that have to do with users
 */
export default class UtilRepository {
    private static baseUrl: string = 'api/util';

    /**
     * gets the user based on the token that is used
     */
    public static testPost(): Promise<AxiosResponse> {
        return ENTITY_CLIENT.post(`${this.baseUrl}/ping`);
    }
}
