
import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import {PaymentType} from "@/enum/PaymentType.enum";

//@ts-ignore
import {loadScript, Payment} from "@paypal/paypal-js";
import {googlePayOptions} from '@/misc/GooglePayOptions';

//@ts-ignore
import braintree from 'braintree-web';
import PaymentRepository from "@/api/repositories/PaymentRepository";
import UtilRepository from "@/api/repositories/UtilRepository";
import PaymentMethod from "@/models/PaymentMethod";

interface AvailablePaymentMethod {
  label: string,
  image: string,
  type: PaymentType
}

@Component
export default class PossiblePaymentListComponent extends Vue {
  @Prop({required: true})
  private companyId!: string;

  @Prop({required: true})
  private onPaypalChanged!: (value: boolean) => void;

  /**
   * TODO: removed for now
   * token that is loaded by the api, used to authorize at braintree
   * @private
   */
  // private token: string | null = null;

  private isDeletingMethod: boolean = false;

  /**
   * flag that indicates if the screen is loading
   * @private
   */
  private isLoadingPossibleMethods = true;

  /**
   * flag that indicates if loading possible payment methods failed
   * @private
   */
  private hasErrorLoadingPossibleMethods = false;

  // TODO: removed for now
  private hasAddedPaypal = false;

  private paymentMethods: PaymentMethod[] = [];

  /**
   * TODO: removed for now
   */
  // private get paypalCredentialMail(): string {
  //   const paypalMethods = this.paymentMethods.filter((m) => m.type === 'paypal');
  //   if(paypalMethods.length <= 0) return '';
  //   return paypalMethods[0]?.paypal.email ?? '';
  // }

  /**
   * TODO: removed for now
   * when using components, vue likes to have the "is" parameter as v-bind,
   * therefore just a getter that returns the script string so vue is happy
   * @private
   */
  // private get isScript() {
  //   return 'script';
  // }

  /**
   * all available payment methods
   * @private
   */
  private availablePaymentMethod: AvailablePaymentMethod[] = [
    {label: this.$t('GENERAL.PAYMENT_METHODS.PAYPAL').toString(), image: '/payment/paypal_icon.png', type: PaymentType.PAYPAL},
  ]

  /**
   * TODO: removed for now
   * @private
   */
  private async setUpPaypal() {
    // const response = await PaymentRepository.setupIntent('PAYPAL', this.companyId);
    // this.token = response.data['braintreeToken'];
    //
    // // TODO: use real client id here
    // // const paypal = await loadScript(({ "client-id": 'sb', vault: true, locale: 'de_DE', intent: 'tokenize', currency: 'EUR',
    // //   "disable-funding": 'card'}));
    //
    // this.isLoadingPossibleMethods = false;
    // this.hasErrorLoadingPossibleMethods = false;
    //
    // const clientInstance = await braintree.client.create({authorization: this.token});
    // const paypalCheckoutInstance = await braintree.paypalCheckout.create({client: clientInstance});
    //
    // try {
    //   await paypal!.Buttons!({
    //     onApprove: (d: any, a: any) => this.onPaypalApproved(d,a, paypalCheckoutInstance),
    //     onError: this.onPaypalError,
    //     createBillingAgreement: function () {
    //       return paypalCheckoutInstance.createPayment({
    //         flow: 'vault'
    //       });
    //     },
    //   }).render('#paypal-button');
    // } catch(_) {
    //   // using here an explicit try and catch for the setup because it can happen that the render process fails, this
    //   // happens when the user is leaving the view before the rendering is fully completed (since the buttons are
    //   // rendered asynchronous), therefore we are just catching this case to avoid showing error messages / toasts but
    //   // there is nothing to do actually inside the catch
    // }
  }

  private async onDeletePaymentClicked() {
    try {
      this.isDeletingMethod = true;
      const paypalMethods = this.paymentMethods.filter((m) => m.type === 'paypal');
      if(paypalMethods.length <= 0) return;

      await PaymentRepository.deletePaymentMethod(paypalMethods[0].id, this.companyId);
      await this.loadPaymentMethods();
      this.hasAddedPaypal = false;
      this.onPaypalChanged(false);
    } finally {
      this.isDeletingMethod = false;
    }
  }

  @Watch('hasAddedPaypal')
  private async onStateChanged() {
    await this.getPossibleMethods();
  }

  public async created() {
    try {
      await UtilRepository.testPost();
    } catch(_) {
      // by default this method will fail on purpose since user has no
      // csrf-token, however by executing this call the user will receive
      // an token afterwards, so this needs to be done in order to make
      // the rest work
    }
    await this.getPossibleMethods();
  }

  /**
   * tries to get all possible payment methods
   * @private
   */
  private async getPossibleMethods() {
    try {
      this.isLoadingPossibleMethods = true;

      // TODO: removed for now
      // await this.setUpPaypal();
      await this.loadPaymentMethods();
    } catch (e) {
      this.hasErrorLoadingPossibleMethods = true;
      this.$notifyErrorSimplified("GENERAL.NOTIFICATIONS.PAYMENT_LOAD_POSSIBLE_METHODS_ERROR");
    } finally {
      this.isLoadingPossibleMethods = false;
    }
  }

  /**
   * loads all payment methods
   * @private
   */
  private async loadPaymentMethods() {
    try {
      const methodResponse = await PaymentRepository.getPaymentMethods(this.companyId);
      this.paymentMethods = methodResponse.data.map((m: PaymentMethod) => PaymentMethod.parseFromObject(m));

      if(this.paymentMethods.filter((m: PaymentMethod) => m.type === 'paypal').length > 0 ) {
        this.hasAddedPaypal = true;
        this.onPaypalChanged(true);
      }
    } finally {
      //
    }
  }

  /**
   * TODO: removed for now
   * is called when paypal was connected
   * @param data
   * @param actions
   * @param paypalCheckoutInstance
   * @private
   */
  // private async onPaypalApproved(data: any, actions: any, paypalCheckoutInstance: any) {
  //   try {
  //     const payload = await paypalCheckoutInstance.tokenizePayment(data);
  //     await PaymentRepository.addPaypalAsPaymentMethod(payload.nonce, this.companyId);
  //     this.onPaypalChanged(true);
  //     this.hasAddedPaypal = true;
  //     await this.loadPaymentMethods();
  //     this.$notifySuccessSimplified('GENERAL.NOTIFICATIONS.PAYMENT_ADD_METHOD.PAYPAL.SUCCESS');
  //   } catch (_) {
  //     this.$notifySuccessSimplified('GENERAL.NOTIFICATIONS.PAYMENT_ADD_METHOD.PAYPAL.ERROR');
  //   }
  // }

  /**
   * TODO: removed for now
   * is called when paypal threw an error during setup
   * @param error
   * @private
   */
  // private onPaypalError(error: any) {
  //   // using here a call-up for checking if the error has to do with the div '#paypal-button', because it can happen
  //   // that the render process fails, this happens when the user is leaving the view before the rendering is fully
  //   // completed (since the buttons are rendered asynchronous),
  //   // therefore we don't want to show the error toast (since there is no problem)
  //   if(error.toString().includes('#paypal-button')) return;
  //   this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.PAYMENT_ADD_METHOD.PAYPAL.ERROR');
  // }
}
