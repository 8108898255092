
import {Component, Prop} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixins';
import Company from "@/models/Company";
//@ts-ignore
import {StripeCheckout, StripeElementCard} from '@vue-stripe/vue-stripe';
import PaymentRepository from "@/api/repositories/PaymentRepository";

//@ts-ignore
import braintree from 'braintree-web';
import PaymentMethod from "@/models/PaymentMethod";
import {PaymentType} from "@/enum/PaymentType.enum";
import {AccountType} from "@/enum/AccountType.enum";
import UtilRepository from "@/api/repositories/UtilRepository";
import OnBoardingProgress from "@/models/OnBoardingProgress";
import CompanyRepository from "@/api/repositories/CompanyRepository";
import {APPLICATION_STORE_NAME, ApplicationStoreActions, ApplicationStoreGetters} from "@/store/application.store";
import {namespace} from "vuex-class";

const ApplicationStore = namespace(APPLICATION_STORE_NAME);

@Component({
  components: {
     StripeConnectComponent: () => import(
      /* webpackChunkName: "StripeConnectComponent" */
      '@/components/Payment/StripeConnect.component.vue'
    ),
    StripeElementCard,
    ConnectedPaymentListItemComponent: () => import(
      /* webpackChunkName: "ConnectedPaymentListItemComponent" */
      '@/components/Payment/ConnectedPaymentListItem.component.vue'
    ),
    PossiblePaymentListComponent: () => import(
      /* webpackChunkName: "PossiblePaymentListComponent" */
      '@/components/Payment/PossiblePaymentList.component.vue'
    ),
    ConfirmDeleteDialog: () => import(
      /* webpackChunkName: "ConfirmDeleteDialog" */
      '@/components/Payment/ConfirmDeletePayment.component.vue'
    ),
    AddCreditCardComponent: () => import(
      /* webpackChunkName: "AddCreditCardComponent" */
      '@/components/Payment/AddCreditCard.component.vue'
    ),
  },
})
export default class EditCompanyPaymentComponent extends mixins(ErrorMessageHandlerMixin) {
  @Prop({required: true})
  private company!: Company;

  @Prop({default: false})
  private showCollapsedVersion!: boolean;

  @Prop({default: false})
  private disableDeletingPaypal!: boolean;

  @ApplicationStore.Action(ApplicationStoreActions.GET_CURRENT_COMPANY)
  private getCurrentCompany!: (id: string) => Promise<Company>;

  private async onGooglePayTokenCreated(value: any) {
    try {
      const decoded = JSON.parse(value['paymentMethodData']['tokenizationData']['token']);
      const {data} = await PaymentRepository.setupIntent('STRIPE', this.company.id);
      const clientSecret = data['clientSecret'];

      // takes the token and client secret to set the payment method for the user
      //@ts-ignore
      const response = await this.$stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: {
            token: decoded.id
          }
        }
      });
      await this.loadPaymentMethods();
      this.$notifySuccessSimplified('GENERAL.NOTIFICATIONS.GOOGLE_PAY.SUCCESS');
    } catch(_) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GOOGLE_PAY.ERROR');
    }
  }

  private get isPb() {
    return this.$hasRole(AccountType.PB);
  }

  /**
   * flag to show the stripe credit card dialog
   * @private
   */
  private showStripeDialog = false;

  /**
   * flag that indicates if the screen is loading
   * @private
   */
  private isLoadingConnectedMethods = false;

  /**
   * flag that indicates if the delete dialog is shown
   */
  private showDeleteDialog = false;

  private get hasPaymentMethods(): boolean {
    if(!this.paymentMethods) return false;
    return this.paymentMethods!.length > 0;
  }

  /**
   *
   * @private
   */
  private clickedPaymentMethod: PaymentMethod | null = null;

  private onDelete(method: PaymentMethod) {
    this.clickedPaymentMethod = method;
    this.showDeleteDialog = true;
  }

  private get showCollapsedPaymentView() {
    if(!this.paymentMethods) return true;
    if(this.paymentMethods?.length > 0) return false;
    return this.showCollapsedVersion;
  }

  /**
   * flat that indicates if loading connected methods failed
   * @private
   */
  private hasErrorLoadingConnectedMethods = false;

  /**
   * stores all payment methods of the user
   * @private
   */
  private paymentMethods: PaymentMethod[] | null = null;

  /**
   * is called whent he confirm delete dialog is closed, checks if the item should be deleted and deletes it (if needed)
   * @param deleted
   * @private
   */
  private async closeConfirmDialog(deleted: boolean) {
    this.clickedPaymentMethod = null;
    this.showStripeDialog = false;
    this.showDeleteDialog = false;
    if(!deleted) return;

    try {
      await this.loadPaymentMethods();
    } catch(_) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.CANNOT_DELETE_NEWS');
    }
  }

  /**
   * loads all payment methods
   * @private
   */
  private async loadPaymentMethods() {
    try {
      this.isLoadingConnectedMethods = true;
      const methodResponse = await PaymentRepository.getPaymentMethods(this.company.id);
      this.paymentMethods = methodResponse.data.map((m: PaymentMethod) => PaymentMethod.parseFromObject(m));
    } catch (_) {
      this.hasErrorLoadingConnectedMethods = true;
    } finally {
      this.isLoadingConnectedMethods = false;
    }
  }

  /**
   * loads payment methods of the user, initializes braintree for paypal
   * @private
   */
  private async created() {
    await this.loadPaymentMethods();
  }

  /**
   * is called when paypal was connected
   * @private
   */
  private async onPaypalApproved(nonce: any) {
    try {
      const id = this.company.id;
      await PaymentRepository.addPaypalAsPaymentMethod(nonce, id);
      await this.loadPaymentMethods();

      const progress = OnBoardingProgress.parseFromObject({
        ...this.company.onboardingProgress,
        paymentAdded: true
      });
      await CompanyRepository.updateCompanyProgress(this.$route.params.id, progress);
      await this.getCurrentCompany(this.$route.params.id);
    } catch (e) {
      throw e;
    }
  }

  /**
   * is called when user clicked on delete payment method
   * @param method
   * @private
   */
  private async onPaymentMethodDelete(method: PaymentMethod): Promise<boolean> {
    try {
      const {data} = await PaymentRepository.deletePaymentMethod(method.id, this.company.id);
      this.$notifySuccessSimplified('GENERAL.NOTIFICATIONS.PAYMENT_DELETE_METHOD.SUCCESS');
      return true;
    } catch(e) {
      this.$handleError(e, () => {
        switch (e.status) {
          case 422:
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.PAYMENT_DELETE_METHOD.ERROR_DEFAULT_METHOD');
            break;
          default:
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.PAYMENT_DELETE_METHOD.ERROR');
        }
      });

      return false;
    }
  }

  /**
   * is called when user clickd on set payment method as default
   * @param method
   * @private
   */
  private async onPaymentSetAsDefault(method: PaymentMethod) {
    try {
      const {data} = await PaymentRepository.setMethodAsDefault(method.id, this.company.id);
      const index = this.paymentMethods?.findIndex((p) => p.id === method.id);
      this.paymentMethods?.forEach((p, i) => p.isDefault = i === index);

      this.$notifySuccessSimplified('GENERAL.NOTIFICATIONS.PAYMENT_SET_AS_DEFAULT_SUCCESS');
    } catch(e) {
      this.$notifySuccessSimplified('GENERAL.NOTIFICATIONS.PAYMENT_SET_AS_DEFAULT_ERROR');
    }
  }

  /**
   * callback when a payment method should be added
   * @param type
   * @private
   */
  private onPaymentMethodAddClicked(type: PaymentType) {
    switch(type) {
      case PaymentType.CREDIT_CARD:
        this.showStripeDialog = true;
        break;

    }
  }
}
